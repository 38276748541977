@import '~antd/dist/antd.min';
@import '~suneditor/dist/css/suneditor.min.css';

.page-header {
  background-color: white;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}

.page-content {
  margin: 24px 16px;
  padding: 24px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
