.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;

  p {
    font-size: 1rem;
    margin: 0;
  }

  em {
    font-size: 0.8rem;
  }
}

.container > .dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-width: 2px;
  cursor: pointer;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #b9b9b9;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.thumbs-container {
  width: 472px;
  overflow: auto;
  margin: 10px 0;
}

.thumb {
  z-index: 1000;
  width: 84px;
  height: 84px;
  margin: 5px;
  text-align: center;
  cursor: move;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  position: relative;

  .thumb-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  button {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
}
